import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {Col, Container, Navbar, NavbarBrand, Nav, Row, Button, FormControl, Spinner} from 'react-bootstrap';

function App() {

  let [downloadValue, setDownloadValue] = useState("");
  let [fetchDoingBackgroundTask, setFetchDoingBackgroundTask] = useState(false);
  let [errorMessageText, setErrorMessageText] = useState("");

  function submit() {
    setFetchDoingBackgroundTask(true);
    setErrorMessageText("");

    console.log(downloadValue);
    try{
      fetch("https://youtube-ripper-backend.t76ftiiraa54m.us-east-1.cs.amazonlightsail.com/rip", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "url": downloadValue
          }
        )
      }).then( res => {
        if (res.status !== 200) {
          throw new Error("There was an error fetching the video. Please try again later.")
        }
        let filename = getFilenameFromContentDisposition(res.headers.get("Content-Disposition"));
        res.blob().then( blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();  //afterwards we remove the element again
        });
      }).catch( err => {
        setFetchDoingBackgroundTask(false);
        setErrorMessageText(getErrorMessage(err));
        console.error(err);
      })
    } catch(e){
      console.error(getErrorMessage(e));
      setErrorMessageText(getErrorMessage(e));
      setFetchDoingBackgroundTask(false)
    }

  }

  function getFilenameFromContentDisposition(contentDispositionHeader: string | null) {
    let filename = "video.mp3";

    if (contentDispositionHeader != null){
      let vals = contentDispositionHeader.split("; ");


      vals.forEach((value, index) => {
        console.log(value)
        if (value.includes("filename=")){
          filename =  value.replace("filename=", "")
        }
      })
    }

    return filename;

  }

  function getErrorMessage(error: unknown): string {
    if (error instanceof Error) return error.message;
    return String(error)
  }

  return (
    <Container>
      <Navbar bg={"dark"} expand={"lg"} variant={"dark"}>
        <Container>
          <NavbarBrand href={"/"}>Gimme MP3</NavbarBrand>
          <Nav className={"me-auto"}>
            <Nav.Link href={"#home"}>aaaa</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Row>
        <Col>
          <h1 className={"mx-auto"} style={{width: "fit-content"}}>Input a YouTube link to download the audio as MP3</h1>
        </Col>
      </Row>
      <Row className={"mx-auto"}>
        <Col xs={4}/>
        <Col xs={3} className={"mx-auto"}>
          <FormControl type="text" id="url" onChange={e => setDownloadValue(e.target.value)}/>
        </Col>
        <Col xs={1} className={"align-content-center"}>
          <Button className={"mx-auto"} name="submit" onClick={submit} disabled={fetchDoingBackgroundTask}>
            {fetchDoingBackgroundTask
              ? <Spinner
                as={"span"}
                animation={"border"}
                size={"sm"}
                />
              : null
            }Fetch</Button>
        </Col>
        <Col xs={4}/>
      </Row>
      <Row>
        <p className={"mx-auto"} style={{color: "darkred", width: "fit-content"}}>{errorMessageText}</p>
      </Row>
    </Container>
  );
}

export default App;
